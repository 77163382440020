
/* Global styles for the entire application */

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f4f4f4;
}

/* Main container for admin sections */
.admin-container {
  width: 80%;
  margin: 20px auto;
  padding: 20px;
  background: white;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  text-align: center;
}

/* Common headers */
h2 {
  margin-bottom: 20px;
}

/* Navigation buttons */
.nav-buttons {
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  gap: 15px;
}

.nav-btn {
  background-color: #28a745;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.nav-btn:hover {
  background-color: #218838;
}

.logout-btn {
  background-color: #dc3545;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.logout-btn:hover {
  background-color: #c82333;
}

/* Tables */
table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

th, td {
  border: 1px solid #ddd;
  padding: 10px;
  text-align: center;
}

th {
  background-color: #f4f4f4;
  font-weight: bold;
}

tr:nth-child(even) {
  background-color: #f9f9f9;
}

/* Buttons styles */
.admin-btn {
  padding: 8px 12px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
}

.admin-btn.add {
  background-color: #007bff;
  color: white;
}

.admin-btn.add:hover {
  background-color: #0056b3;
}

.admin-btn.edit {
  background-color: #ffc107;
  color: black;
}

.admin-btn.edit:hover {
  background-color: #e0a800;
}

.admin-btn.save {
  background-color: #17a2b8;
  color: white;
}

.admin-btn.save:hover {
  background-color: #138496;
}

.admin-btn.delete {
  background-color: #dc3545;
  color: white;
}

.admin-btn.delete:hover {
  background-color: #c82333;
}

/* Login Page */
.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f4f4f4;
}

.login-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.login-form input {
  width: 250px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.login-button {
  background-color: #007bff;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.login-button:hover {
  background-color: #0056b3;
}

/* Dashboard, Account & Bloggers Styles */
.dashboard-container, .account-container, .bloggers-container {
  width: 80%;
  margin: 20px auto;
  padding: 20px;
  background: white;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  text-align: center;
}

h2 {
  margin-bottom: 20px;
}

/* Dashboard Buttons */
.dashboard-buttons {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-top: 20px;
}

.dashboard-btn {
  background-color: #007bff;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.dashboard-btn.logout {
  background-color: #dc3545;
}

.dashboard-btn:hover {
  background-color: #0056b3;
}

/* Account Settings */
.account-container input {
  width: 250px;
  padding: 10px;
  margin: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.account-btn {
  background-color: #28a745;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.account-btn:hover {
  background-color: #218838;
}

/* Bloggers List */
.blogger-list {
  list-style: none;
  padding: 0;
  margin-top: 20px;
}

.blogger-item {
  background: #f4f4f4;
  padding: 10px;
  margin: 5px 0;
  border-radius: 5px;
}
